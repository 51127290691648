import React, { Component } from "react";
import { Form, Typography, Input, Button, Select, Image, Alert, Row, Col, Steps } from "antd";
import alertIcon from '../../assets/images/pending.png';
import success from '../../assets/images/success.svg';
import { setAddressStep } from "../../reducers/addressBookReducer";
import { setAddress, setStep, setWithdrawcrypto, rejectWithdrawfiat, setSendCrypto, hideSendCrypto } from "../../reducers/sendreceiveReducer";
import { connect } from "react-redux";
import { getCryptoData, saveCryptoData, getCoinList, getWalletSource, getNetWorkLucup, uuidv4, getuserSatoshiTestDetails, getWalletTypeLu, sendProof, isErrorDispaly, selfSave } from "./api";
import Loader from '../../Shared/loader';
import { validateContentRule } from "../../utils/custom.validator";
import Translate from "react-translate-component";
import apicalls from "../../api/apiCalls";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment-timezone";
import { addressRegex, assets, getaddressFormat } from "../../utils/addressRegexAndFormats";
const { Text, Title, Paragraph } = Typography;
const { Option } = Select;
const { Step } = Steps;
const convertToLocalTime = (date, isLocal = false) => {
  const userTimeZone = moment.tz.guess();
  if (isLocal) {
    return moment(date).format("DD-MM-YYYY hh:mm A");
  } else {
    const localTime = moment.utc(date).tz(userTimeZone).format("DD-MM-YYYY hh:mm A");
    return localTime;
  }
};
const selfSignURL = process.env.REACT_APP_SELF_SIGN_URL
class AddressCrypto extends Component {
  form = React.createRef();
  iframeRef = React.createRef()
  useDivRef = React.createRef()
  constructor(props) {
    super(props);
    this.selfSignHandler = this.selfSignHandler.bind(this);
    this.state = {
      errorMessage: null,
      isLoading: true,
      details: {},
      isBtnLoading: false,
      showDeclartion: false,
      cryptoData: {},
      coinsList: [],
      networksList: [],
      wallet: [],
      isEdit: false,
      documents: null,
      walletSource: null,
      walletSourse: null,
      check: false,
      showDeclartionApproved: false,
      approvedAddress: false,
      isDocCheck: false,
      isDocDeleteCheck: false,
      netWorkData: [],
      showIframe: false,
      iframeId: '',
      iframeObj: { saveVal: {}, saveObj: {}, saveRes: {} },
      satoshiTestDetails: null,
      walletTypeOptions: [],
      proofType: null,
      isIframeLoading: true,
      savingSelfSignature: false,
      selfSignatureSaved: false,
      addressFormatForURL:''
    };

  }

  componentDidMount() {
    this.getCryptoData();
    this.handleWallet();
    this.getNetWorkData();
    this.getWalletTypeLookup();
    window.addEventListener('message', this.selfSignHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.selfSignHandler)
  }
  async selfSignHandler(event) {
    const iframeWindow = this.iframeRef.current?.contentWindow
    let messageForIframe = {
      type: 'SELF_SIGN_EVENT',
      error: '',
      isSuccess: false
    }
    try {
      const origin = `${event.origin}/`
      if (origin !== selfSignURL) {
        return;
      }
      this.setState((prev) => ({ ...prev, savingSelfSignature: true }))
      const receivedData = event.data;
      if (receivedData.iframId !== this.state.iframeId) {
        messageForIframe = { ...messageForIframe, error: `Invalid Message` }
        this.setState((prev) => ({ ...prev, savingSelfSignature: false }))
        return;
      }
      const _obj = {
        "customerId": this.props.userProfile.id,
        "walletAddress": receivedData.walletAddress,
        "network": this.state.cryptoData?.network || this.state.iframeObj?.saveObj?.network,
        "message": this.state?.iframeId,
        'hash': receivedData.hash,
        "iframId": this.state?.iframeId,
        'proofType': 'Self',
        'transactionDate': new Date().toISOString().split('.')[0],
        'CreatedBy': this.props.userProfile?.userName,
        "PayeeId":this?.state?.iframeObj?.saveRes?.data?.payeeAccountId
      }
      this.setState((prev) => ({ ...prev, isBtnLoading: true, errorMessage: null }));
      const response = await selfSave(_obj);
      if (response.status === 200) {
        
        if (this.props.type !== "manual") {
          messageForIframe = { ...messageForIframe, error: '', isSuccess: true }
        this.setState((prev) => ({ ...prev, savingSelfSignature: false, selfSignatureSaved: true, isBtnLoading: false, errorMessage: null, showDeclartion: false, showDeclartionApproved: false, approvedAddress: false }));
          this.renderSuccessData(this?.state?.iframeObj?.saveVal,this?.state?.iframeObj?.saveObj,this?.state?.iframeObj?.saveRes)
        }
        else{
          messageForIframe = { ...messageForIframe, error: '', isSuccess: true }
          this.setState((prev) => ({ ...prev, savingSelfSignature: false, selfSignatureSaved: true, isBtnLoading: false, errorMessage: null, showDeclartion: true, showDeclartionApproved: false, approvedAddress: false }));
        }
      } else if (response.status === 522 || response.status === 480) {
        const { errors: { DomainValidations } } = response.data;
        messageForIframe = { ...messageForIframe, error: DomainValidations[0] }
        this.setState((prev) => ({ ...prev, savingSelfSignature: false, isBtnLoading: false }));
      }
      else {
        messageForIframe = { ...messageForIframe, error: isErrorDispaly(response) }
        this.setState((prev) => ({ ...prev, savingSelfSignature: false, isBtnLoading: false }));
      }
    } catch (error) {
      messageForIframe = { ...messageForIframe, error: isErrorDispaly(error) }
      this.setState((prev) => ({ ...prev, savingSelfSignature: false }))
    } finally {
      iframeWindow?.postMessage(messageForIframe, selfSignURL)
    }
  }

  convertUTCToLocalTime = (dateString) => {
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    );
    return new Date(milliseconds)
  };

  getNetWorkData = async () => {
    const newIframeId = uuidv4();
    this.setState((prev) => ({ ...prev, iframeId: newIframeId, showIframe: false }));
    let response = await getNetWorkLucup();
    if (response.ok) {
      this.setState((prev) => ({ ...prev, netWorkData: response.data }))
    } else {
      this.setState((prev) => ({ ...prev, errorMessage: apicalls.isErrorDispaly(response) }))
    }
  }

  getCryptoData = async () => {
    let id = this.props?.addressBookReducer?.selectedRowData?.id || this.props.cryptoId || "00000000-0000-0000-0000-000000000000";
    this.setState((prev) => ({ ...prev, isLoading: true }))
    let response = await getCryptoData(id);
    if (response.ok) {
      this.setState((prev) => ({ ...prev, cryptoData: response.data, isLoading: false, isEdit: true, check: response.data.isOwnerOfWalletAddress, isDocCheck: response.data.isDocumentUpload, walletSourse: response.data?.walletSource }))
      this.form?.current?.setFieldsValue(response.data);
    }
    else {
      this.setState((prev) => ({ ...prev, isLoading: false, errorMessage: apicalls.isErrorDispaly(response) }))
    }
    this.coinList();
  }

  coinList = async () => {
    let response = await getCoinList("All")
    if (response.ok) {
      this.setState((prev) => ({ ...prev, coinsList: response.data, isLoading: false }))
    } else {
      this.setState((prev) => ({ ...prev, coinsList: [], isLoading: false }))
    }
    if (this.state.cryptoData.network) {
      this.form?.current?.setFieldsValue({ network: this.state.cryptoData.network })
    }
    else if (this.props.sendReceive?.withdrawFiatObj?.walletCode) {
      this.form?.current?.setFieldsValue({ token: this.props.sendReceive?.withdrawFiatObj?.walletCode })
      let val = this.props.sendReceive?.withdrawFiatObj?.walletCode
      this.handleTokenChange(val);
    }
    else if (this.props?.sendReceive?.cryptoWithdraw?.selectedWallet?.coin !== " "
      || this.props?.sendReceive?.cryptoWithdraw?.selectedWallet?.coin != null ||
      this.props?.sendReceive?.cryptoWithdraw?.selectedWallet?.coin !== undefined) {
      let val = this.props?.sendReceive?.cryptoWithdraw?.selectedWallet?.coin
      this.form?.current?.setFieldsValue({ token: val });
      this.handleTokenChange(val);
    }
  }

  handleTokenChange = (value) => {
    this.form?.current?.setFieldsValue({ network: null });
    let walletAddress = this.form?.current?.getFieldValue("walletAddress");
    if (value && walletAddress) {
      this.form?.current?.validateFields(["walletAddress"], this.validateAddressType(value))
    }
    let networkLu = null;
    if (value) {
      this.state?.coinsList?.filter(function (item) {
        if (item.walletCode === value) {
          networkLu = item?.network;
          return true;
        }
        return false;
      })
    }
    this.setState((prev) => ({ ...prev, networksList: networkLu }))
  };

  handleWalletSource = (value) => {
    if (this.state.cryptoData?.id === "00000000-0000-0000-0000-000000000000" || (this.state.cryptoData?.id != "00000000-0000-0000-0000-000000000000" && this.state.cryptoData.walletSource != "Others")) {
      this.form?.current?.setFieldsValue({ otherWallet: null });
    }
    this.setState((prev) => ({ ...prev, walletSourse: value }))
  }

  handleProofType = async () => {
    let _network = this.form?.current?.getFieldValue('network');
    let _walletAddress = this.form?.current?.getFieldValue('walletAddress');
    let _proofType = this.form?.current?.getFieldValue('proofType');
    let response = await getuserSatoshiTestDetails(_network, _walletAddress);
    if (response.status === 200) {
      this.setState((prev) => ({ ...prev, showIframe: false, proofType: _proofType, satoshiTestDetails: response.data }));
      return response.data
    }
    else {
      this.setState((prev) => ({ ...prev,isBtnLoading: false, showIframe: false, proofType: _proofType, satoshiTestDetails: null, errorMessage: apicalls.isErrorDispaly(response) }));
      return null
    }
  }

  handleWalletType = async (value) => {
    this.setState((prev) => ({ ...prev, proofType: value, showIframe: false }));
  }

  getWalletTypeLookup = async () => {
    let response = await getWalletTypeLu();
    if (response.status === 200) {
      this.setState((prev) => ({ ...prev, walletTypeOptions: response.data }));
    }
    else {
      this.setState((prev) => ({ ...prev, errorMessage: apicalls.isErrorDispaly(response) }));
    }

  }

  handleWallet = async () => {
    let res = await getWalletSource();
    if (res.ok) {
      this.setState((prev) => ({ ...prev, wallet: res.data }))
    }
  }


  shouldShowDeclaration = (values) => {
    return !(this.state.cryptoData.adressstate === "Approved" && (this.state.cryptoData.isDocumentUpload === false || values.isDocumentUpload === null))
      || (this.state.cryptoData.walletSource === null && (this.state.cryptoData.isOwnerOfWalletAddress || this.state.cryptoData.isOwnerOfWalletAddress === null))
  }

  renderSuccessData = (values, obj, response) => {
    if ((window?.location?.pathname.includes('addressbook') || this.props.isSave) && this.props.type === "manual") {
      if (this.shouldShowDeclaration(values)) {
        this.setState((prev) => ({ ...prev, errorMessage: null, isBtnLoading: false, showDeclartion: true, showDeclartionApproved: false, approvedAddress: false }));
      } else if ((obj.docRepositories?.length === 0 || obj.docRepositories?.length === undefined || this.state.cryptoData?.documents === undefined) && this.state.cryptoData.adressstate === "Approved"
        && (this.state.cryptoData.isDocumentUpload === false || values.isDocumentUpload === null || values.isDocumentUpload === false)
      ) {
        this.setState((prev) => ({ ...prev, errorMessage: null, isBtnLoading: false, showDeclartion: false, showDeclartionApproved: false, approvedAddress: true }));
      }
      else {
        this.setState((prev) => ({ ...prev, errorMessage: null, isBtnLoading: false, showDeclartion: false, showDeclartionApproved: true, approvedAddress: false }));
      }
      this.props.headingUpdate(true)
    }
    else {
      let _obj = this.props.sendReceive?.withdrawCryptoObj;
      this.props?.dispatch(setWithdrawcrypto({ ..._obj, addressBookId: response.data?.payeeAccountId || response.data?.id, toWalletAddress: values?.walletAddress, network: values?.network, isShowDeclaration: true }));
      this.props.dispatch(rejectWithdrawfiat());
      this.props.dispatch(hideSendCrypto(false));
      this.props.dispatch(setSendCrypto(true));
      this.props.changeStep('withdraw_crpto_summary');
    }
  }

  submit = async (values) => {
    if (this.state.cryptoData.adressstate === "Approved") {
      this.useDivRef.current?.scrollIntoView();
      this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: 'You cannot edit an address that has already been approved.', loading: false }));
      return;
    }
    
    this.setState((prev) => ({ ...prev, isBtnLoading: true, errorMessage: null }))
    let satoshiDetails={};
    if (values?.proofType === 'Satoshi Test') {
      satoshiDetails=await this.handleProofType()
    }
    if(!satoshiDetails){
      return;
    }
    const date=new Date().toISOString().split('.')[0]
    let obj = {
      id: "00000000-0000-0000-0000-000000000000",
      saveWhiteListName: values.saveWhiteListName,
      token: values.token || this.props?.selectedcoin || null,
      network: values.network,
      createddate: this.state.cryptoData?.createddate || date,
      userCreated: this.state.cryptoData?.userCreated || this.props.userProfile.userName,
      modifiedDate: date,
      modifiedBy: this.props.userProfile.userName,
      status: 1,
      adressstate: "fd",
      currencyType: "Crypto",
      walletAddress: values.walletAddress.trim(),
      customerId: this.props.userProfile.id,
      walletSource: values.walletSource,
      otherWallet: values.otherWallet,
      docRepositories: this.state.details.docRepositories,
      createdBy: this.props.userProfile?.userName,
      info: JSON.stringify(this.props?.trackAuditLogData),
      "AnalyticsId": this.state?.iframeId,
      proofType: values?.proofType,
      amount:satoshiDetails.amount
    }

    if (this.state.cryptoData.id !== "00000000-0000-0000-0000-000000000000") {
      obj.id = this.state.cryptoData.id;
    }
    let response = await saveCryptoData(obj)
    if (response.ok) {
      const addressFormatForURL=getaddressFormat(values.network,values.walletAddress.trim())
      this.setState(prevState => ({
        ...prevState,
        isBtnLoading: false,
        errorMessage: null,
        showIframe: true,
        proofType: values?.proofType,
        iframeObj: {
          ...prevState?.iframeObj,
          saveVal: values, saveObj: obj, saveRes: response
        },
        addressFormatForURL,
      }));

    }
    else {
      this.useDivRef.current?.scrollIntoView();
      this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: apicalls.isErrorDispaly(response), loading: false }));
    }


  }

  validateAddressType = (value, network) => {
    if (!value || !network) {
      return Promise.resolve();
    }
    const address = value.trim();
    const isValidAddress = addressRegex[network?.toLowerCase()]?.test(address)
    if (!isValidAddress) {
      return Promise.reject("Address is not valid, Please enter a valid address according to the network selected")
    }
    return Promise.resolve();

  };

  renderWalletSourceField = () => {
    return process.env.REACT_APP_ISTR === "true" && <> <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
      <Form.Item className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
        name="walletSource"
        label="Wallet Source"
        rules={[
          {
            required: true,
            message: "Is required",
          },
        ]}
      >
        <Select
          className={`cust-input ${(this.state.cryptoData.adressstate === "Approved" && this.state.cryptoData.walletSource !== null) ? "input-disabled-style" : ""}`}
          maxLength={100}
          placeholder="Select Wallet Source"
          optionFilterProp="children"
          onChange={this.handleWalletSource}
          disabled={this.state.cryptoData.adressstate === "Approved"}
        >
          {this.state.wallet?.map((item, idx) => (
            <Option key={idx} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
      {this.state.walletSourse === "Others" && <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item
          className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
          name="otherWallet"
          label="You have selected others for Wallet Source. Please specify"
          required
          rules={[
            {
              whitespace: true,
              message: "Is required",
            },
            {
              required: true,
              message: "Is required",
            },
            {
              validator: validateContentRule,
            },
          ]}
        >
          <Input
            className="cust-input"
            maxLength={100}
            placeholder="Wallet Source"
            disabled={(this.state.cryptoData.adressstate === "Approved" && this.state.cryptoData.walletSource !== null) ? true : false}
          />
        </Form.Item>
      </Col>}

      <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
          name="proofType"
          label="Proof Type"
          rules={[
            {
              required: true,
              message: "Is required",
            },
          ]}
        >
          <Select
            className={`cust-input`}
            maxLength={100}
            placeholder="Select Proof Type"
            optionFilterProp="children"
            onChange={this.handleWalletType}
            disabled={this.state.cryptoData.adressstate === "Approved"}
          >
            {this.state.walletTypeOptions?.map((item, idx) => (
              <Option key={idx} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  }

  renderAlertMsg = () => (

    this.state.errorMessage && <Alert type="error" description={this.state.errorMessage} showIcon />

  )

  renderWalletAddress = () => (
    <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
      <Form.Item
        className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
        name="walletAddress"
        label="Wallet Address"
        required
        rules={[
          {
            required: true,
            message: "Is required",
          },
          {
            validator: (_, value) => {
              const network = this.form.current?.getFieldValue('network')
              return this.validateAddressType(value, network)
            },
          }
        ]}
      >
        <Input
          className="cust-input"
          maxLength={100}
          placeholder="Wallet Address"
          disabled={this.state.cryptoData.adressstate === "Approved"}
        />
      </Form.Item>
    </Col>

  )

  renderCryptoSaveBtns = () => (
    <Form.Item className="">
      <Button
        htmlType="submit"
        size="large"
        block
        className="pop-btn"
        loading={this.state.isBtnLoading}
        disabled={this.state.cryptoData.adressstate === "Approved"}
      >
        {this.props.type === "manual" && "Next"}
        {this.props.type !== "manual" && <Translate content="continue" />}
      </Button>
    </Form.Item>
  )

  backToDashboard = () => {
    this.props.history.push("/cockpit");
  }

  renderSatioshiSuccessData = async () => {
    try {
      let _transactionId = this.form.current.getFieldValue('transactionId');
      const { fromAddress, proofType, asset, status, amount,createdDate } = this.state.satoshiTestDetails
      const _obj = {
        "customerId": this.props.userProfile.id,
        "walletAddress": fromAddress,
        "proofType": proofType,
        // "transactionDate": new Date().toISOString().split('.')[0],
        "transactionId": _transactionId,
        "network": asset,
        "status": status,
        "iframId": this.state?.iframeId,
        "amount": amount,
        "transactionDate": createdDate,
        "PayeeId":this?.state?.iframeObj?.saveRes?.data?.payeeAccountId
      }
      this.setState((prev) => ({ ...prev, isBtnLoading: true, errorMessage: null }))
      const response = await sendProof(_obj);
      if (response.status === 200) {
        if (this.props.type !== "manual") {
          this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: null,showDeclartion:false, showDeclartionApproved: false, approvedAddress: false }));
          this.renderSuccessData(this?.state?.iframeObj?.saveVal,this?.state?.iframeObj?.saveObj,this?.state?.iframeObj?.saveRes)
        }
        else {
          this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: null, showDeclartion: true, showDeclartionApproved: false, approvedAddress: false }));

        }

      } else if (response.status === 522 || response.status === 480) {
        const { errors: { DomainValidations } } = response.data;
        this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: DomainValidations[0] }));
        this.useDivRef.current?.scrollIntoView();
      } else {
        this.useDivRef.current?.scrollIntoView();
        this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: isErrorDispaly(response) }));
      }
    } catch (satoshiSaveError) {
      this.useDivRef.current?.scrollIntoView();
      this.setState((prev) => ({ ...prev, isBtnLoading: false, errorMessage: isErrorDispaly(satoshiSaveError) }));
    }

  }

  handleNetwork = (value) => {
    this.setState((prev) => ({ ...prev, type: 'network', network: value, proofType: null, errorMessage: null }));
    this.form.current?.resetFields(['proofType', 'walletAddress']);
  }

  render() {
    const { isLoading, showDeclartion, cryptoData, showDeclartionApproved, approvedAddress, showIframe, iframeObj, satoshiTestDetails, proofType } = this.state;
    if (isLoading) {
      return <Loader />
    }
    if (showDeclartion) {
      return <div className="custom-declaraton align-declaration">
        {process.env.REACT_APP_ISDOCUSIGN == "false" && <div className="success-pop text-center declaration-content">
          <Image preview={false} src={alertIcon} className="confirm-icon" />
          <Title level={2} className="success-title">Declaration form sent successfully</Title>
          <Text className="successsubtext">{`Declaration form has been sent to ${this.props.userProfile?.email}.
              Please sign using link received in email to whitelist your address. Please note that any transactions regarding this whitelist will only be processed once your whitelisted address has been approved.`}</Text>
        </div> || <div className="success-pop text-center declaration-content">
            <Image src={success} className="confirm-icon" alt={"success"} preview={false} />
            <Title level={2} className="success-title">Address saved successfully</Title>
            <p className="text-white">Please note that any transactions regarding this whitelist will only be processed once your whitelisted address has been approved</p>
            {this.props.isShowBtn &&
              <Button
                onClick={() => this.backToDashboard()}
                htmlType="submit"
                size="large"
                block
                className="pop-btn"
                loading={this.state.isBtnLoading}
              >
                Back to Dashboard
              </Button>}
          </div>}
      </div>
    } else if (showDeclartionApproved) {
      return <div className="custom-declaraton travel-success"> <div className="success-pop text-center declaration-content">
        <Image src={success} className="confirm-icon" alt={"success"} preview={false} />
        <Title level={2} className="success-title">Document uploaded successfully</Title>
      </div>
      </div>
    }

    else if (approvedAddress) {
      return <div className="custom-declaraton travel-success"> <div className="success-pop text-center declaration-content">
        <Image src={success} className="confirm-icon" alt={"success"} preview={false} />
        <Title level={2} className="success-title">Address Saved successfully</Title>
      </div>
      </div>
    }

    else if (showIframe) {
      if (proofType === 'Self') {
        return <div className="custom-declaraton travel-success"> <div className="success-pop text-center declaration-content">
          <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="d-flex crypto-anylytics-note">
            <p className="text-white">Note :&nbsp;</p>
            <Translate className="sub-abovesearch" content="ifreame_Note" component={Text} />
          </Col>
          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            {this.renderAlertMsg()}</Col>
          <Col xs={24} md={24} lg={24} xl={24} xxl={24} className="self-sign">
            {(this.state.isIframeLoading || this.state.savingSelfSignature) && <Loader />}
            {this.state.savingSelfSignature && <Text className="text-white save-sign">Saving signature...</Text>}
            {!this.state.selfSignatureSaved && <iframe
              title="Self Signature"
              ref={this.iframeRef}
              id="cryptoSelfSignIframe"
              src={`${selfSignURL}${this.state?.iframeId}/${assets[cryptoData?.network?.toLowerCase() || iframeObj?.saveObj?.network?.toLowerCase()]}/${encodeURIComponent(this.state.addressFormatForURL)}`}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                zIndex: 9999,
                borderRadius: '20px'
              }}
              onLoad={() => this.setState((prev) => ({ ...prev, isIframeLoading: false }))}
              allow={`usb 'self' ${process.env.REACT_APP_WEB_URL} ${selfSignURL}`}
            ></iframe>}
          </Col>
        </div>
        </div>
      }

      else if (proofType === 'Satoshi Test') {
        return (
          <Form
            className="custom-label"
            ref={this.form}
            onFinish={this.renderSatioshiSuccessData}
            autoComplete="off"
          >
            <>
              <div ref={this.useDivRef}></div>
              {this.renderAlertMsg()}
              <div className="cust-summary-new">
                <div>
                  <div className="pay-list" style={{ alignItems: ' baseline' }}>
                    <div className="summary-liststyle">Proof:</div>
                    <div className="summarybal wallet-copy-width">{this.state?.iframeId}</div>
                  </div>
                  <div className="pay-list" style={{ alignItems: ' baseline' }}>
                    <div className="summary-liststyle">Proof Type:</div>
                    <div className="summarybal">Satoshi Test</div>
                  </div>
                  <div className="pay-list" style={{ alignItems: ' flex-start' }}>
                    <div className="summary-liststyle">Wallet Address:</div>
                    <div className="summarybal wallet-copy-width"><CopyToClipboard text={satoshiTestDetails?.fromAddress} options={{ format: 'text/plain' }}>
                      <Text copyable={{ tooltips: [apicalls.convertLocalLang('copy'), apicalls.convertLocalLang('copied')] }} className="fait-subtext cust-copy-icon">
                        {satoshiTestDetails?.fromAddress}
                      </Text>
                    </CopyToClipboard></div>
                  </div>

                  <div className="pay-list" style={{ alignItems: ' baseline' }}>
                    <div className="summary-liststyle">Asset:</div>
                    <div className="summarybal">{satoshiTestDetails?.asset}</div>
                  </div>
                  <div className="pay-list" style={{ alignItems: ' baseline' }}>
                    <div className="summary-liststyle">Date Created:</div>
                    <div className="summarybal">
                      {(cryptoData?.createddate || iframeObj?.saveObj?.createddate) && convertToLocalTime(cryptoData?.createddate || iframeObj?.saveObj?.createddate)}
                    </div>
                  </div>
                </div>
              </div>
              <Title className="adbook-head">Verify This Proof</Title>
              <Steps direction="vertical" current={1} className="cust-steps-design">

                <Step title={<b className="text-white">Please follow the instruction below:</b>} description={<>{satoshiTestDetails && <span className="recive-runtext">{`Send ${satoshiTestDetails?.amount} ${satoshiTestDetails?.code}(${satoshiTestDetails?.asset}) from ${satoshiTestDetails?.fromAddress} to ${satoshiTestDetails?.toAddress} by ${satoshiTestDetails?.createdDate ? convertToLocalTime(satoshiTestDetails?.createdDate.split('.')[0]): ''} to prove your control over the address.`}</span>}<br /> <span className="recive-copy">

                  <CopyToClipboard text={satoshiTestDetails?.toAddress} options={{ format: 'text/plain' }}>
                    <Text copyable={{ tooltips: [apicalls.convertLocalLang('copy'), apicalls.convertLocalLang('copied')] }} className="fait-subtext cust-copy-icon">
                      {satoshiTestDetails?.toAddress}
                    </Text>

                  </CopyToClipboard></span></>} />

                <Step title={<b className="text-white">Find Transaction ID</b>} description={<><span className="recive-runtext">{`To verify ownership of ${satoshiTestDetails?.fromAddress} find a transaction of ${satoshiTestDetails?.amount} ${satoshiTestDetails?.code}(${satoshiTestDetails?.asset}) by inspecting the block explorer page for the deposit address`}</span><br /> <span className="recive-copy">

                  {<div className="recive-copy">
                    <CopyToClipboard text={satoshiTestDetails?.fromAddress} options={{ format: 'text/plain' }}>
                      <Text copyable={{ tooltips: [apicalls.convertLocalLang('copy'), apicalls.convertLocalLang('copied')] }} className="fait-subtext cust-copy-icon">
                        {satoshiTestDetails?.fromAddress}
                      </Text>
                    </CopyToClipboard>
                  </div>}</span></>} />

                <Step title={<div className="last-title">

                  <Form.Item
                    className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"
                    name="transactionId"
                    label="Transaction ID"
                    rules={[
                      {
                        whitespace: true,
                        message: "Is required",
                      },
                      {
                        required: true,
                        message: "Is required",
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            !/^[A-Za-z0-9]+$/.test(value)
                          ) {
                            return Promise.reject(
                              "Invalid Transaction ID"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }
                    ]}
                  >
                    <Input
                      className="cust-input"
                      maxLength={200}
                      placeholder="Transaction ID"
                    />
                  </Form.Item>
                </div>} description={<> <Form.Item className="">
                  <Button
                    htmlType="submit"
                    size="medium"
                    block
                    className="pop-btn"
                    loading={this.state.isBtnLoading}
                  // onClick={() => this.renderSatioshiSuccessData()}
                  >
                    {this.props.type !== "manual" ? "Proceed" : "Save"}
                  </Button>
                </Form.Item></>} />
              </Steps>
            </>
          </Form>)
      }
    }
    else {

      return <>
        {this.props?.isShowheading && <div className="text-center">
          <Paragraph className='drawer-maintitle' >Add Crypto Address</Paragraph>
        </div>}
        <div ref={this.useDivRef}></div>
        {this.renderAlertMsg()}
        <Form
          className="custom-label"
          ref={this.form}
          onFinish={this.submit}
          scrollToFirstError
        >

          <Form.Item className="custom-forminput custom-label sc-error addcrypto-whitelist"

            name="saveWhiteListName"

            label="Save Whitelist Name As"

            rules={[
              {
                whitespace: true,
                message: "Is required",
              },
              {
                required: true,
                message: "Is required",
              },
              {
                validator: validateContentRule,
              },

            ]}

          >

            <Input className="cust-input" maxLength={100} placeholder="Save Whitelist Name As" disabled={this.state.cryptoData.adressstate === "Approved"} />

          </Form.Item>

          <div className="">

            <Title className="adbook-head">Beneficiary Details</Title>

          </div>

          <Row className="addcrypto-benficiary">

            <Col xs={24} md={24} lg={24} xl={24} xxl={24}>

              <Form.Item className=" mb-8 px-4 text-white-50 custom-forminput custom-label pt-8 sc-error"

                name="network"
                label="Network (Any coins on the selected network will be whitelisted)"
                rules={[
                  {
                    required: true,
                    message: "Is required",
                  },
                ]}
              >
                <Select
                  className={`cust-input ${this.state.cryptoData.adressstate === "Approved" ? "input-disabled-style" : ""}`}
                  maxLength={100}
                  placeholder="Select Network"
                  optionFilterProp="children"
                  onChange={this.handleNetwork}
                  disabled={this.state.cryptoData.adressstate === "Approved"}
                >
                  {this.state.netWorkData?.map((item, idx) => (
                    <Option key={item.walletCode} value={item.walletCode}>
                      {item.walletCode}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {this.renderWalletAddress()}
            {this.renderWalletSourceField()}
          </Row>
          {this.renderCryptoSaveBtns()}
        </Form>
      </>

    }

  }

}

const connectStateToProps = ({ sendReceive, userConfig, addressBookReducer }) => {

  return { addressBookReducer, sendReceive, userProfile: userConfig.userProfileInfo }

}

const connectDispatchToProps = dispatch => {

  return {

    changeStep: (stepcode) => {

      dispatch(setAddressStep(stepcode)); dispatch(setStep(stepcode))

    },

    SelectedAddress: (addressObj) => {

      dispatch(setAddress(addressObj));

    },

    dispatch

  }

}



export default connect(connectStateToProps, connectDispatchToProps)(withRouter(AddressCrypto));

